/*eslint-disable*/
import React, { useState } from "react";
import { Link, useHref } from "react-router-dom";
import logo from "../../assets/myimg/logo.png";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function WhiteNavbar({navbarSelection}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [activeLink,setActiveLink] = useState(1)
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
      <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img src={logo} alt="logo" width={200} height="auto" title="Ilkem Granite North" loading="eager"/>
            </NavbarBrand>
            {/* <UncontrolledTooltip target="navbar-brand">
              Designed by Invision. Coded by Creative Tim
            </UncontrolledTooltip> */}
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="m-auto" id="ceva" navbar>
              <NavItem>
                <NavLink href="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem className={navbarSelection == "explore-stones" ? "active" : ""}>
                <NavLink href="/explore-stones" >
                  Explore Stones
                </NavLink>
              </NavItem>
              <NavItem className={navbarSelection == "about-us" ? "active" : ""}>
                <NavLink href="/about-us">
                  About Us
                </NavLink>
              </NavItem>
              <NavItem className={navbarSelection == "gallery" ? "active" : ""}>
                <NavLink href="/gallery">
                  Gallery
                </NavLink>
              </NavItem>
              
            </Nav>
            <Nav id="ceva2" navbar>
              <NavItem>
                <Button
                  className="nav-link btn-default"
                  color="info"
                  href="/contact-us"
          
                >
                  <p>Contact Us</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
