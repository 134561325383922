import React, { useState } from "react";
import axios from "axios";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';


// core components
import ContactUsHeader from "components/ContactUs/ContactUsHeader";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import { Helmet } from "react-helmet-async";

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state?.data || {};
  console.log(receivedData)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: (location.state?.data ? `I want to get a special offer for ${receivedData?.id} number ${receivedData?.name} ${receivedData?.type}. Please contact me.(You can add extra information)` : ""),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post("https://topstonesolutions.pythonanywhere.com/api/contact/", formData);
      // Optionally reset the form data
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate("/contact-complete");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (show an error message)
    }
  };

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    // document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
    <Helmet>
      <title>Contact Ilkem Granite North | Premium Stone Solutions in North Caldwell, NJ</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Contact Ilkem Granite North for expert stone assistance in North Caldwell, NJ. Transform your spaces with quartz, granite, marble, and quartzite."
        data-rh="true"
      />
      <link rel="canonical" href='/contat-us' />
      <meta property="og:title" content="Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Ilkem Granite North. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.ilkemgranitenorth.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.ilkemgranitenorth.com/contact-us" />
    </Helmet>
    <WhiteNavbar navbarSelection={"contact-us"}/>
      <div className="wrapper">
        <br/><br/>
        <div className="main">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                <h1 style={{display:"none"}}>Ilkem Granite North Contact Form</h1>
                  <h2 className="title">Send us a message</h2>
                  <h3 style={{display:"none"}}>Ilkem Granite North, Get in Touch</h3>
                  <p className="description">
                    You can contact us with anything related to our Products.
                    We'll get in touch with you as soon as possible. <br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" onSubmit={handleSubmit}>
                    <label>Your name</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="name"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="Your Name..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Email address</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Phone</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons tech_mobile"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="phone"
                        name="phone"
                        required
                        value={formData.phone}
                        onChange={handleInputChange}
                        autoComplete="number"
                        placeholder="Number Here..."
                        type="text"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label for="message">Your message</label>
                      <Input
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        color="info"
                        defaultValue="Contact Us"
                        type="submit"
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at the office</h4>
                      <p>
                      1 Fairfield Rd, North Caldwell, <br></br>
                      NJ, 07006, <br></br>
                        United States
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p>
                        (973) 891-5566 <br></br>
                        Mon - Fri, 8:00-22:00
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="business_briefcase-24 now-ui-icons"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Legal Information</h4>
                      <p>
                       Ilkem Marble and Granite Inc. <br></br>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <hr></hr>
    <footer className="footer" style={{paddingTop:"0"}}> 
      <Container>
      <div className="copyright" id="copyright">© {new Date().getFullYear()}, Designed by{" "}<a className="text-danger" href="https://topstonesolutions.com/">Topstone Solutions</a></div>
    </Container>
    </footer>
      </div>
    </>
  );
}

export default ContactUs;
