import React from "react";
import Headers from "../components/headers/Headers";

import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";

import StoneExplains from "components/StoneExplains/StoneExplains";
import Footer from "components/Footers/Footer";
import { Container } from "reactstrap";
import data from "../data.json";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const allCollection = [
    ...new Set(
      Object.values(data).map(
        (product) => product.Color
        // {
        //     const allCollection2 = [product.Color]
        //     return allCollection2;
        // }
      )
    ),
  ];
  console.log([...new Set(allCollection.flat())]);
  React.useEffect(() => {
    document.body.classList.add("sections-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Helmet>
        <title>
        Ilkem Granite North | Quality Stone Surfaces in North Caldwell
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Discover quartz, granite, marble, and quartzite surfaces at Ilkem Granite North. Elevate your spaces with premium stones in North Caldwell, NJ."
          data-rh="true"
        />
        <link rel="canonical" href="/"/>
        <meta property="og:title" content="Ilkem Granite North | Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Ilkem Granite North. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.ilkemgranitenorth.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.ilkemgranitenorth.com/home" />
      </Helmet>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <Headers />
        <StoneExplains />
        <Footer />
      </div>
    </>
  );
};

export default Home;
