/*eslint-disable*/
import React, { useEffect } from "react";
import quartz from "../../assets/myimg/quartz.png";
import dekton from "../../assets/myimg/dekton.png";
import granite from "../../assets/myimg/granite.png";
import marble from "../../assets/myimg/marble.png";
import quartzite2 from "../../assets/myimg/quartzite2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

const StoneExplains = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <div className="projects-5">
        <Container style={{overflow: "hidden"}}>
          <Row>
            <Col className="ml-auto mr-auto text-center" data-aos="fade-down" data-aos-duration="1000" md="8">
              <h2 className="title" >Explore our stones</h2>
              <h3 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces at Ilkem Granite North.</h3>
              <h4  className="description">
                Step into the world of precious stones. There are many products
                here that will appeal to your eyes.
              </h4>
              <div  id="quartz" className="section-space"></div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Quartz &#8764;</h3>
            </Col>
            <Col className="ml-auto" md="5" data-aos="fade-down-right" data-aos-duration="3000" >
              <img alt="quartz" src={quartz} width="auto" height="auto" title="quartz" loading="lazy"/>
            </Col>
            <Col className="mr-auto" md="5" data-aos="fade-left" data-aos-duration="3000">
              <div className="info info-horizontal"  data-aos-offset="100">
                <div className="icon icon-danger">
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Durability and Low Maintenance</h4>
                  <p className="description">
                    Excellent resistance to scratches, stains and heat. Easy to clean and look like the first day for many years
                  </p>
                </div>
              </div>
              <div className="info info-horizontal" >
                <div className="icon icon-danger">
                  <i className="now-ui-icons shopping_shop"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Color and Pattern Options</h4>
                  <p className="description">
                  Endless design with rich color scale and unique patterns
                  </p>
                </div>
              </div>
              <div className="info info-horizontal" >
                <div className="icon icon-danger">
                  <i className="now-ui-icons objects_umbrella-13"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Hygienic and Healthy</h4>
                  <p className="description">
                  Healthy environment with germ and bacteria resistance. Stain and dirt repellent surfaces
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div id="dekton" className="section-space"></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Dekton &#8764;</h3>
            </Col>
            <Col className="ml-auto mt-5 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
              <div className="info info-horizontal">
                <div className="icon icon-black">
                  <i className="now-ui-icons arrows-1_refresh-69"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Ultra-compact Rich Blend</h4>
                  <p className="description">
                  A sophisticated blend of over 20 minerals extracted from nature
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-black">
                  <i className="now-ui-icons objects_diamond"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">The Name of Perfection</h4>
                  <p className="description">
                  Perfection in functional beauty in every architectural project
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-black">
                  <i className="now-ui-icons objects_umbrella-13"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">UV and temperature resistant</h4>
                  <p className="description">
                  Excellent results on all surfaces with resistance to UV rays and extreme temperatures
                  </p>
                </div>
              </div>
            </Col>
            <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5">
              <img alt="dekton" src={dekton} width="auto" height="auto" title="dekton" loading="lazy"/>
            </Col>
          </Row>
          <div id="granite" className="section-space"></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Granite &#8764;</h3>
            </Col>
            <Col className="ml-auto" data-aos="fade-down-right" data-aos-duration="3000" md="5">
              <img alt="granite" src={granite} width="auto" height="auto" title="granite" loading="lazy"/>
            </Col>
            <Col className="mr-auto" data-aos="fade-left" data-aos-duration="3000" md="5">
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Durability</h4>
                  <p className="description">
                  Long-lasting thanks to its hard and dense structure. Resistant to scratches, impact and abrasion
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons design_palette"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Aesthetic Appearance</h4>
                  <p className="description">
                  A rich visual appeal with different color and pattern options
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons objects_umbrella-13"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Hygienic and Healthy</h4>
                  <p className="description">
                  Healthy environment with germ and bacteria resistance. Stain and dirt repellent surfaces
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div id="marble" className="section-space"></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Marble &#8764;</h3>
            </Col>
            <Col className="ml-auto mt-5 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
              <div className="info info-horizontal">
                <div className="icon icon-warning">
                  <i className="now-ui-icons objects_diamond"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Visual Appeal</h4>
                  <p className="description">
                  A perfect aesthetic and sophisticated look for all spaces
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-warning">
                  <i className="now-ui-icons shopping_shop"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Durable and Long Lasting</h4>
                  <p className="description">
                  Excellent resistance to scratches, stains and heat.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-warning">
                  <i className="now-ui-icons objects_umbrella-13"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Easy to Clean</h4>
                  <p className="description">
                  Easy to clean and look like the first day for many years
                  </p>
                </div>
              </div>
            </Col>
            <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5">
              <img alt="marble" src={marble} width="auto" height="auto" title="marble" loading="lazy"/>
            </Col>
          </Row>
          <div id="quartizite" className="section-space"></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 id="quartizite" className="title">&#8764; Quartzite &#8764;</h3>
            </Col>
            <Col className="ml-auto" data-aos="fade-down-right" data-aos-duration="3000" md="5">
              <img alt="quartz" src={quartzite2} width="auto" height="auto" title="quartzite" loading="lazy"/>
            </Col>
            <Col className="mr-auto" data-aos="fade-left" data-aos-duration="3000" md="5">
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Aesthetic and Durable</h4>
                  <p className="description">
                  An excellent choice with a captivating appearance and long-lasting structure
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons design_palette"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Unique Colors and Patterns</h4>
                  <p className="description">
                  A distinctive choice with unique natural colors and patterns that reflect your personal style
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="now-ui-icons objects_umbrella-13"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">Natural and Elegant</h4>
                  <p className="description">
                  Combines natural stone texture with modern elegance
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default StoneExplains;
