import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet-async";

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = [];
      for (let i = 133; i >= 1; i--) {
        const image = {
          original: require(`../assets/ilkem-gallery/img${i.toString().padStart(3, '0')}.jpg`),
          thumbnail: require(`../assets/ilkem-gallery/img${i.toString().padStart(3, '0')}.jpg`),
          originalHeight: "auto",
          originalWidth: "auto",
          thumbnailHeight:"auto",
          thumbnailWidth: "auto",
          loading:"eager",
          thumbnailLoading:"eager",
          originalAlt:"Kitchen, Bathroom Countertops",
          thumbnailAlt:"Kitchen, Bathroom Countertops",
          originalTitle:"Kitchen, Bathroom Countertops",
          thumbnailTitle:"Kitchen, Bathroom Countertops",

        };
        fetchedImages.push(image);
      }
      setImages(fetchedImages);
    };
    fetchImages();
  }, []);
  document.body.classList.add("sidebar-collapse");
  return (
    <> 
    <Helmet>
      <title>Stone Surfaces Gallery | Quartz, Granite, Marble, Quartzite</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Explore stunning quartz, granite, marble, and quartzite installations. Get inspired for your project with Ilkem Granite North's gallery."
        data-rh="true"
      />
      <link rel="canonical" href='/gallery' />
      <meta property="og:title" content="Ilkem Granite North | Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Ilkem Granite North. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.ilkemgranitenorth.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.ilkemgranitenorth.com/gallery" />
    </Helmet>
    <WhiteNavbar navbarSelection={"gallery"} />
    
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div class="row">
      <div class="mr-auto ml-auto">
      <h1 style={{display:"none"}}>Ilkem Granite North Gallery</h1>
       <h2 class="title d-flex">
          <div>
		        <div class="side2">You&nbsp;</div>
		        <div class="side2">We&nbsp;</div>
		       <div class="side2">You&nbsp;</div>
	        </div>
        <div>
          <div class="side1">Dream it..</div>
          <div class="side1">Make it.</div>
          <div class="side1">Dream it..</div>
        </div>
      </h2></div></div>
    <ImageGallery items={images} showIndex={true}/>
    <hr></hr>
    <footer className="footer" style={{paddingTop:"0"}}> 
      <Container>
      <div className="copyright" id="copyright">© {new Date().getFullYear()}, Designed by{" "}<a className="text-danger" href="https://topstonesolutions.com/">Topstone Solutions</a></div>
    </Container>
    </footer>
     
      
    </>
  );
};

export default Gallery;
